var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "pages.playroomSearch.bookingPolicyTitle",
            expression: "'pages.playroomSearch.bookingPolicyTitle'"
          }
        ],
        staticClass: "title"
      }),
      _vm._l(_vm.$v.bookingPolicies.$each.$iter, function(bookingPolicy) {
        return _c(
          "div",
          { key: bookingPolicy.$model.name },
          [
            _vm.showFilter(bookingPolicy)
              ? _c("ValidatedCheckbox", {
                  attrs: {
                    field: bookingPolicy.hasFilter,
                    checked: bookingPolicy.hasFilter.$model,
                    translationKey:
                      "playroomSearch." + bookingPolicy.name.$model
                  },
                  on: {
                    "update:checked": function($event) {
                      return _vm.$set(bookingPolicy.hasFilter, "$model", $event)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }