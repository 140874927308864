var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CDataTable",
    _vm._b(
      {
        attrs: {
          hover: "",
          "no-items-view": {
            noItems: _vm.$t("components.DataTable.noItems"),
            noResults: _vm.$t("components.DataTable.noItems")
          },
          "table-filter": _vm.tableFilter,
          tableFilterValue: _vm.tableFilterProxy,
          sorter: { external: true, resetable: true },
          sorterValue: _vm.sorterValueProxy
        },
        on: {
          "row-clicked": function(item, index) {
            return _vm.$emit("row-clicked", item, index)
          },
          "update:tableFilterValue": function($event) {
            _vm.tableFilterProxy = $event
          },
          "update:table-filter-value": function($event) {
            _vm.tableFilterProxy = $event
          },
          "update:sorterValue": function($event) {
            _vm.sorterValueProxy = $event
          },
          "update:sorter-value": function($event) {
            _vm.sorterValueProxy = $event
          }
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function(_, slot) {
              return {
                key: slot,
                fn: function(scope) {
                  return [_vm._t(slot, null, null, scope)]
                }
              }
            })
          ],
          null,
          true
        )
      },
      "CDataTable",
      _vm.attrs(),
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }