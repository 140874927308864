var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("BookingPolicyFilterView", {
        attrs: {
          bookingPolicies: _vm.bookingPolicies,
          showFreeCancellation: _vm.showFreeCancellation
        },
        on: { change: _vm.emitChange }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }