var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { staticClass: "breadcrumb" },
    [
      _vm._l(_vm.linkItems, function(item, index) {
        return _c(
          "li",
          {
            key: index,
            class: [item.addClasses, _vm.sharedClasses, _vm.addLinkClasses],
            attrs: { role: "presentation" }
          },
          [
            item.to
              ? _c(
                  "span",
                  [
                    _c(
                      "CLink",
                      _vm._b(
                        {},
                        "CLink",
                        Object.assign({}, item, {
                          addClasses: null,
                          text: null
                        }),
                        false
                      ),
                      [
                        item.text
                          ? _c("span", [_vm._v(_vm._s(item.text))])
                          : _vm._e(),
                        item.translationKey
                          ? _c("span", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value: item.translationKey,
                                  expression: "item.translationKey"
                                }
                              ]
                            })
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            !item.to ? _c("span", [_vm._v(_vm._s(item.text))]) : _vm._e()
          ]
        )
      }),
      _vm.lastItem
        ? _c(
            "li",
            { class: _vm.lastItemClasses, attrs: { role: "presentation" } },
            [
              _vm.lastItem.text
                ? _c("span", [_vm._v(_vm._s(_vm.lastItem.text))])
                : _vm._e(),
              _vm.lastItem.translationKey
                ? _c("span", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: _vm.lastItem.translationKey,
                        expression: "lastItem.translationKey"
                      }
                    ]
                  })
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }