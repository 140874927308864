var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CHeader",
    { attrs: { "with-subheader": "" } },
    [
      _c("CToggler", {
        staticClass: "ml-3 d-lg-none",
        attrs: {
          "in-header": "",
          "data-testid": "toggle-mobile-sidebar-header"
        },
        on: {
          click: function($event) {
            return _vm.$store.commit("UiStoreModule/toggleSidebarMobile")
          }
        }
      }),
      _c("CToggler", {
        staticClass: "ml-3 d-md-down-none",
        attrs: { "in-header": "" },
        on: {
          click: function($event) {
            return _vm.$store.commit("UiStoreModule/toggleSidebarDesktop")
          }
        }
      }),
      _c(
        "CHeaderBrand",
        { staticClass: "mx-auto d-lg-none", attrs: { to: { name: "Home" } } },
        [
          _vm.isDarkMode
            ? _c("CImg", {
                attrs: {
                  src: "/images/logos/logo_transparent_horizontal.png",
                  alt: "My Playrooms Logo"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "span",
        {
          staticStyle: {
            display: "flex",
            width: "90%",
            "justify-content": "flex-end"
          }
        },
        [
          !_vm.isLoggedIn
            ? _c(
                "div",
                [
                  _c(
                    "CHeaderNav",
                    { staticClass: "d-md-down-none mr-auto" },
                    [
                      _c(
                        "CHeaderNavItem",
                        { staticClass: "px-3" },
                        [
                          _c("CButton", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value: "components.TheHeader.login",
                                expression: "'components.TheHeader.login'"
                              }
                            ],
                            attrs: {
                              to: { name: "Login" },
                              "data-testid": "login-link-header",
                              color: "primary",
                              variant: "outline"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.maintenanceMode
                        ? _c(
                            "CHeaderNavItem",
                            { staticClass: "px-3" },
                            [
                              _c("CButton", {
                                directives: [
                                  {
                                    name: "t",
                                    rawName: "v-t",
                                    value: "components.TheHeader.register",
                                    expression:
                                      "'components.TheHeader.register'"
                                  },
                                  {
                                    name: "c-tooltip",
                                    rawName: "v-c-tooltip",
                                    value: {
                                      appendToBody: true,
                                      content: _vm.$t(
                                        "components.TheHeader.registerDisabled"
                                      )
                                    },
                                    expression:
                                      "{\n              appendToBody: true,\n              content: $t('components.TheHeader.registerDisabled'),\n            }"
                                  }
                                ],
                                attrs: { disabled: "", color: "info" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.maintenanceMode
                        ? _c(
                            "CHeaderNavItem",
                            { staticClass: "px-3" },
                            [
                              _c(
                                "CDropdown",
                                {
                                  attrs: {
                                    "toggler-text": _vm.$t(
                                      "components.TheHeader.register"
                                    ),
                                    color: "info",
                                    "data-testid": "register-dropdown-header"
                                  }
                                },
                                [
                                  _c("CDropdownItem", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "components.TheHeader.registerAsCustomer",
                                        expression:
                                          "'components.TheHeader.registerAsCustomer'"
                                      }
                                    ],
                                    attrs: {
                                      to: { name: "RegisterCustomer" },
                                      "data-testid":
                                        "register-as-customer-link-header"
                                    }
                                  }),
                                  _c("CDropdownItem", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "components.TheHeader.registerAsOwner",
                                        expression:
                                          "'components.TheHeader.registerAsOwner'"
                                      }
                                    ],
                                    attrs: {
                                      to: { name: "RegisterOwner" },
                                      "data-testid":
                                        "register-as-owner-link-header"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isLoggedIn
            ? _c(
                "div",
                [
                  _c(
                    "CHeaderNav",
                    { staticClass: "d-md-down-none d-lg-flex mr-auto" },
                    [
                      _vm.currentUser.role == "Customer"
                        ? _c(
                            "CHeaderNavItem",
                            { staticClass: "px-3" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: { name: "CustomerAccountEdit" } }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.currentUser.displayName))
                                  ])
                                ]
                              ),
                              _vm._v(" (" + _vm._s(_vm.roleTranslation) + ") ")
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentUser.role == "Owner"
                        ? _c(
                            "CHeaderNavItem",
                            { staticClass: "px-3" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "HostAccountEdit" } } },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.currentUser.displayName))
                                  ])
                                ]
                              ),
                              _vm._v(" (" + _vm._s(_vm.roleTranslation) + ") ")
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentUser.role == "Admin"
                        ? _c("CHeaderNavItem", { staticClass: "px-3" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.currentUser.displayName) +
                                " (" +
                                _vm._s(_vm.roleTranslation) +
                                ") "
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "CHeaderNavItem",
                        { staticClass: "px-3" },
                        [
                          _c("CButton", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value: "components.TheHeader.logout",
                                expression: "'components.TheHeader.logout'"
                              }
                            ],
                            attrs: { color: "info", variant: "outline" },
                            on: {
                              click: function($event) {
                                return _vm.logout()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.canGoBackToAdmin
                        ? _c(
                            "CHeaderNavItem",
                            { staticClass: "px-3" },
                            [
                              _c("CButton", {
                                directives: [
                                  {
                                    name: "t",
                                    rawName: "v-t",
                                    value: "components.TheHeader.backToAdmin",
                                    expression:
                                      "'components.TheHeader.backToAdmin'"
                                  }
                                ],
                                attrs: {
                                  color: "warning",
                                  variant: "outline",
                                  "data-testid": "back-to-admin-header"
                                },
                                on: { click: _vm.goBackToAdmin }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "CHeaderNav",
            { staticClass: "d-sm-down-none" },
            [
              _c(
                "CHeaderNavItem",
                { staticClass: "px-3" },
                [_c("OfficeModeButton")],
                1
              )
            ],
            1
          ),
          _c(
            "CHeaderNav",
            [!_vm.isProduction ? _c("LanguageSwitch") : _vm._e()],
            1
          )
        ],
        1
      ),
      _c(
        "CSubheader",
        { staticClass: "px-3" },
        [_c("DynamicBreadcrumb", { staticClass: "border-0 mb-0" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }