var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready && _vm.bookingType === "overnight"
    ? _c(
        "div",
        [
          _c("ValidatedInput", {
            attrs: {
              translationKey: "playroomSearch.overnightCheckIn",
              field: _vm.$v.checkInDate,
              value: _vm.$v.checkInDate.$model,
              type: "date"
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(_vm.$v.checkInDate, "$model", $event)
              }
            }
          }),
          _c("ValidatedInput", {
            attrs: {
              translationKey: "playroomSearch.overnightCheckOut",
              field: _vm.$v.checkOutDate,
              value: _vm.$v.checkOutDate.$model,
              type: "date",
              min: new Date()
            },
            on: {
              "update:value": function($event) {
                return _vm.$set(_vm.$v.checkOutDate, "$model", $event)
              }
            }
          }),
          _c("CButton", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "pages.playroomSearch.overnightBookingDataClear",
                expression: "'pages.playroomSearch.overnightBookingDataClear'"
              }
            ],
            attrs: { color: "secondary" },
            on: {
              click: function($event) {
                return _vm.clearData()
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }