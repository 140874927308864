var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CModal",
    {
      attrs: {
        title: _vm.$t("components.messages.DeleteConfirmationModal.title"),
        color: "danger",
        centered: true,
        show: _vm.show
      },
      on: { "update:show": _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("CButton", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "global.no",
                    expression: "'global.no'"
                  }
                ],
                attrs: { color: "light" },
                on: {
                  click: function($event) {
                    return _vm.onClose()
                  }
                }
              }),
              _c("CButton", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "global.yes",
                    expression: "'global.yes'"
                  }
                ],
                attrs: { color: "danger" },
                on: {
                  click: function($event) {
                    return _vm.onOk()
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [_c("div", [_vm._v(" " + _vm._s(_vm.displayMessage) + " ")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }