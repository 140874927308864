var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isProduction
    ? _c(
        "div",
        { staticClass: "bg-warning p-3 mb-2 lead" },
        [
          _c("CIcon", {
            staticClass: "mr-1",
            attrs: { name: "cil-warning", size: "lg" }
          }),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("components.DemoHeader.text", {
                  environmentName: _vm.environmentName
                })
              ) +
              " "
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }