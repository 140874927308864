var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CToaster",
    { attrs: { autohide: 5000 } },
    [
      _vm._l(_vm.toasts, function(toast, index) {
        return [
          _c(
            "CToast",
            {
              key: "toast" + index,
              class: toast.cssClass,
              attrs: { show: true, header: "Information" }
            },
            [_vm._v(" " + _vm._s(toast.message) + " ")]
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }