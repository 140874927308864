var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CModal",
    {
      staticClass: "notification-modal",
      attrs: { color: "info", show: _vm.show, "data-testid": _vm.testid },
      on: {
        "update:show": function($event) {
          return _vm.onClose()
        }
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("components.Notification.title")))]
          },
          proxy: true
        },
        {
          key: "header-wrapper",
          fn: function() {
            return [_c("div")]
          },
          proxy: true
        },
        {
          key: "footer-wrapper",
          fn: function() {
            return [_c("div")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { staticClass: "image", attrs: { md: "3" } },
            [_c("CIcon", { attrs: { name: _vm.icon, size: "3xl" } })],
            1
          ),
          _c("CCol", {
            attrs: { md: "9" },
            domProps: { innerHTML: _vm._s(_vm.text) }
          }),
          _c(
            "CCol",
            { staticClass: "button", attrs: { md: "12" } },
            [
              _c("CButton", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "components.Notification.button",
                    expression: "'components.Notification.button'"
                  }
                ],
                attrs: {
                  color: "primary",
                  "data-testid": "notification-button"
                },
                on: {
                  click: function($event) {
                    return _vm.onClose()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }