var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "CButton",
        {
          directives: [
            {
              name: "c-tooltip",
              rawName: "v-c-tooltip",
              value: {
                appendToBody: true,
                content: _vm.$t("components.OfficeModeButton.tooltip")
              },
              expression:
                "{ appendToBody: true, content: $t('components.OfficeModeButton.tooltip') }"
            }
          ],
          attrs: {
            variant: "outline",
            color: _vm.isDarkMode ? "warning" : "info",
            "data-cy": "darkMode"
          },
          on: {
            click: function() {
              return _vm.$store.dispatch("UiStoreModule/toggleDarkMode")
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.officeModeButtonText) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }