var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CSidebar",
    {
      attrs: {
        minimize: _vm.minimize,
        unfoldable: "",
        show: _vm.show,
        "data-cy": "sidebar"
      },
      on: {
        "update:show": function(value) {
          return _vm.$store.commit("UiStoreModule/setSidebarShow", value)
        }
      }
    },
    [
      !_vm.minimize
        ? _c(
            "CSidebarBrand",
            { attrs: { to: "/" } },
            [
              _vm.isDarkMode
                ? _c("CImg", {
                    staticClass: "logo",
                    attrs: {
                      src: "/images/logos/logo_transparent_horizontal.png",
                      alt: "My Playrooms Logo"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "CSidebarNav",
        [
          _c("CSidebarNavItem", {
            attrs: { name: "Home", to: { name: "Home" }, icon: "cil-home" }
          }),
          _c("CSidebarNavItem", {
            attrs: {
              name: "Playrooms",
              to: { name: "PublicPlayroomIndex" },
              icon: "cil-location-pin",
              "data-testid": "sidebar-playrooms"
            }
          }),
          _vm.currentUser && _vm.currentUser.role == "Customer"
            ? [
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.CustomerOrderIndex"),
                    to: { name: "CustomerOrderIndex" },
                    icon: "cil-card-travel",
                    "data-cy": "order-sidebar"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.CustomerMessageIndex"),
                    to: { name: "CustomerMessageIndex" },
                    icon: "cil-envelope-closed",
                    "data-cy": "messages-sidebar",
                    badge: {
                      text: _vm.status.unreadMessageCount,
                      color:
                        _vm.status.unreadMessageCount > 0 ? "info" : "success"
                    }
                  }
                }),
                _c(
                  "CSidebarNavDropdown",
                  {
                    attrs: {
                      icon: "cil-settings",
                      name: _vm.$t("components.TheSidebar.CustomerAccountEdit"),
                      "data-cy": "sidebar-CustomerAccountEdit"
                    }
                  },
                  [
                    _c("CSidebarNavItem", {
                      attrs: {
                        name: _vm.$t("components.TheSidebar.CustomerProfile"),
                        to: {
                          name: "CustomerAccountEdit",
                          hash: "#guest-profile"
                        },
                        icon: "cil-user",
                        "data-cy": "customer-profile-sidebar"
                      }
                    }),
                    _c("CSidebarNavItem", {
                      attrs: {
                        to: {
                          name: "CustomerAccountEdit",
                          hash: "#change-password"
                        },
                        name: _vm.$t("components.TheSidebar.ChangePassword"),
                        icon: "cil-lock-locked",
                        "data-cy": "sidebar-change-password"
                      }
                    }),
                    _c("CSidebarNavItem", {
                      attrs: {
                        to: {
                          name: "CustomerAccountEdit",
                          hash: "#change-email"
                        },
                        name: _vm.$t("components.TheSidebar.ChangeEmail"),
                        icon: "cil-at",
                        "data-cy": "sidebar-change-email"
                      }
                    }),
                    _c("CSidebarNavItem", {
                      attrs: {
                        to: {
                          name: "CustomerAccountEdit",
                          hash: "#change-email-settings"
                        },
                        name: _vm.$t(
                          "components.TheSidebar.ChangeEmailSettings"
                        ),
                        icon: "cil-at",
                        "data-cy": "sidebar-change-email-settings"
                      }
                    }),
                    _c("CSidebarNavItem", {
                      attrs: {
                        to: { name: "CustomerDeleteAccount" },
                        name: _vm.$t(
                          "components.TheSidebar.CustomerDeleteAccount"
                        ),
                        icon: "cil-warning",
                        "data-cy": "sidebar-customer-delete-account"
                      }
                    }),
                    _c(
                      "li",
                      {
                        staticClass: "c-sidebar-nav-item",
                        on: { click: _vm.logout }
                      },
                      [
                        _c(
                          "a",
                          { staticClass: "c-sidebar-nav-link" },
                          [
                            _c("CIcon", {
                              staticClass: "c-sidebar-nav-icon",
                              attrs: { name: "cil-account-logout" }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("components.TheSidebar.logout")) +
                                " "
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.currentUser && _vm.currentUser.role == "Owner"
            ? [
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.OwnerOrderIndex"),
                    to: { name: "OwnerOrderIndex" },
                    icon: "cil-cart",
                    badge: {
                      text: _vm.status.newOrderCount,
                      color: _vm.status.newOrderCount > 0 ? "info" : "success"
                    }
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.OwnerOrderCalendar"),
                    to: { name: "OwnerOrderCalendar" },
                    icon: "cil-calendar"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.OwnerMessageIndex"),
                    to: { name: "OwnerMessageIndex" },
                    icon: "cil-envelope-closed",
                    badge: {
                      text: _vm.status.unreadMessageCount,
                      color:
                        _vm.status.unreadMessageCount > 0 ? "info" : "success"
                    }
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.HostChatMessageIndex"),
                    to: { name: "HostChatMessageIndex" },
                    icon: "cil-chat-bubble",
                    badge: {
                      text: _vm.hostUnreadChatMessageCount,
                      color:
                        _vm.hostUnreadChatMessageCount > 0 ? "info" : "success"
                    }
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.OwnerDashboardIndex"),
                    to: { name: "OwnerDashboardIndex" },
                    icon: "cil-chart"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.OwnerPlayroomIndex"),
                    to: { name: "OwnerPlayroomIndex" },
                    icon: "cil-house"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.HostTemplateTextIndex"),
                    to: { name: "HostTemplateTextIndex" },
                    icon: "cil-envelope-closed"
                  }
                }),
                _vm.status.subscriptedModules.includes("VoucherManagement")
                  ? _c("CSidebarNavItem", {
                      attrs: {
                        name: _vm.$t("components.TheSidebar.HostVoucherIndex"),
                        to: { name: "HostVoucherIndex" },
                        icon: "cis-card-membership"
                      }
                    })
                  : _vm._e(),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.HostModulesIndex"),
                    to: { name: "HostModulesIndex" },
                    icon: "cil-widgets"
                  }
                }),
                _c(
                  "CSidebarNavDropdown",
                  {
                    attrs: {
                      icon: "cil-settings",
                      name: _vm.$t("components.TheSidebar.HostAccountEdit"),
                      "data-cy": "sidebar-HostAccountEdit"
                    }
                  },
                  [
                    _c("CSidebarNavItem", {
                      attrs: {
                        name: _vm.$t("components.TheSidebar.HostProfile"),
                        to: { name: "HostAccountEdit", hash: "#host-profile" },
                        icon: "cil-user",
                        "data-cy": "customer-profile-sidebar"
                      }
                    }),
                    _c("CSidebarNavItem", {
                      attrs: {
                        to: {
                          name: "HostAccountEdit",
                          hash: "#change-password"
                        },
                        name: _vm.$t("components.TheSidebar.ChangePassword"),
                        icon: "cil-lock-locked",
                        "data-cy": "sidebar-change-password"
                      }
                    }),
                    _c("CSidebarNavItem", {
                      attrs: {
                        to: { name: "HostAccountEdit", hash: "#change-email" },
                        name: _vm.$t("components.TheSidebar.ChangeEmail"),
                        icon: "cil-at",
                        "data-cy": "sidebar-change-email"
                      }
                    }),
                    _c("CSidebarNavItem", {
                      attrs: {
                        to: {
                          name: "HostAccountEdit",
                          hash: "#change-email-settings"
                        },
                        name: _vm.$t(
                          "components.TheSidebar.ChangeEmailSettings"
                        ),
                        icon: "cil-at",
                        "data-cy": "sidebar-change-email-settings"
                      }
                    }),
                    _c(
                      "li",
                      {
                        staticClass: "c-sidebar-nav-item",
                        on: { click: _vm.logout }
                      },
                      [
                        _c(
                          "a",
                          { staticClass: "c-sidebar-nav-link" },
                          [
                            _c("CIcon", {
                              staticClass: "c-sidebar-nav-icon",
                              attrs: { name: "cil-account-logout" }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("components.TheSidebar.logout")) +
                                " "
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.currentUser && _vm.currentUser.role == "Admin"
            ? [
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.AdminDashboardIndex"),
                    to: { name: "AdminDashboardIndex" },
                    icon: "cil-chart"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.AdminPlayroomIndex"),
                    to: { name: "AdminPlayroomIndex" },
                    icon: "cil-building"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.AdminMessageIndex"),
                    to: { name: "AdminMessageIndex" },
                    icon: "cil-envelope-closed",
                    badge: {
                      text: _vm.status.unreadMessageCount,
                      color:
                        _vm.status.unreadMessageCount > 0 ? "info" : "success"
                    }
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.AdminCustomerIndex"),
                    to: { name: "AdminCustomerIndex" },
                    icon: "cil-people",
                    "data-cy": "sidebar-all-customers"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.AdminOwnerIndex"),
                    to: { name: "AdminOwnerIndex" },
                    icon: "cil-people",
                    "data-cy": "sidebar-all-owners"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.AdminOrderIndex"),
                    to: { name: "AdminOrderIndex" },
                    icon: "cil-cart"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t(
                      "components.TheSidebar.AdminMoneyTransactionIndex"
                    ),
                    to: { name: "AdminMoneyTransactionIndex" },
                    icon: "cil-dollar"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t(
                      "components.TheSidebar.AdminTemplateTextIndex"
                    ),
                    to: { name: "AdminTemplateTextIndex" },
                    icon: "cil-envelope-closed"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.AdminVoucherIndex"),
                    to: { name: "AdminVoucherIndex" },
                    icon: "cis-card-membership"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.AdminMigrateData"),
                    to: { name: "AdminMigrateData" },
                    icon: "cil-envelope-closed"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.AdminTransactionIndex"),
                    to: { name: "AdminTransactionIndex" },
                    icon: "cil-envelope-closed"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t(
                      "components.TheSidebar.AdminModuleSubscriptions"
                    ),
                    to: { name: "AdminModuleSubscriptionsIndex" },
                    icon: "cil-widgets"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t(
                      "components.TheSidebar.AdminPlayroomRatingsIndex"
                    ),
                    to: { name: "AdminPlayroomRatingsIndex" },
                    icon: "cil-envelope-closed"
                  }
                }),
                _c("CSidebarNavItem", {
                  attrs: {
                    name: _vm.$t("components.TheSidebar.AdminPartnerImport"),
                    to: { name: "AdminPartnerImport" },
                    icon: "cil-envelope-closed"
                  }
                })
              ]
            : _vm._e(),
          _c("CSidebarNavDivider"),
          _vm.isLoggedIn && _vm.currentUser.role == "Admin"
            ? [
                _c(
                  "CSidebarNavDropdown",
                  {
                    attrs: {
                      icon: "cil-settings",
                      name: _vm.$t("components.TheSidebar.settings"),
                      "data-cy": "sidebar-settings"
                    }
                  },
                  [
                    _c("CSidebarNavItem", {
                      attrs: {
                        to: { name: "ChangePassword" },
                        name: _vm.$t("components.TheSidebar.ChangePassword"),
                        icon: "cil-lock-locked",
                        "data-cy": "sidebar-change-password"
                      }
                    }),
                    _c("CSidebarNavItem", {
                      attrs: {
                        to: { name: "ChangeEmail" },
                        name: _vm.$t("components.TheSidebar.ChangeEmail"),
                        icon: "cil-at",
                        "data-cy": "sidebar-change-email"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          !_vm.isLoggedIn
            ? [
                _c(
                  "li",
                  { staticClass: "c-sidebar-nav-item login icon" },
                  [
                    _vm._t("default", [
                      _c(
                        "CLink",
                        {
                          staticClass: "c-sidebar-nav-link",
                          attrs: { to: { name: "Login" } }
                        },
                        [
                          _c("LoginIcon", {
                            staticClass: "c-sidebar-nav-icon"
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("components.TheSidebar.login")) +
                              " "
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  2
                ),
                !_vm.maintenanceMode
                  ? _c("CSidebarNavItem", {
                      attrs: {
                        to: { name: "RegisterCustomer" },
                        name: _vm.$t("components.TheSidebar.RegisterCustomer"),
                        icon: "cilNote"
                      }
                    })
                  : _vm._e(),
                !_vm.maintenanceMode
                  ? _c("CSidebarNavItem", {
                      attrs: {
                        to: { name: "RegisterOwner" },
                        name: _vm.$t("components.TheSidebar.RegisterOwner"),
                        icon: "cilNote"
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.isLoggedIn && _vm.canGoBackToAdmin
            ? [
                _c(
                  "li",
                  {
                    staticClass: "c-sidebar-nav-item",
                    on: { click: _vm.goBackToAdmin }
                  },
                  [
                    _c(
                      "a",
                      { staticClass: "c-sidebar-nav-link" },
                      [
                        _c("CIcon", {
                          staticClass: "c-sidebar-nav-icon",
                          attrs: { name: "cil-account-logout" }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("components.TheSidebar.backToAdmin")
                            ) +
                            " "
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            : _vm._e(),
          _c(
            "li",
            {
              staticClass: "c-sidebar-nav-item d-lg-none",
              on: {
                click: function() {
                  return _vm.$store.dispatch("UiStoreModule/toggleDarkMode")
                }
              }
            },
            [
              _c(
                "span",
                { staticClass: "c-sidebar-nav-link" },
                [
                  _c("CIcon", {
                    staticClass: "c-sidebar-nav-icon",
                    attrs: { name: "cil-building" }
                  }),
                  _c("span", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "components.TheSidebar.officeMode",
                        expression: "'components.TheSidebar.officeMode'"
                      }
                    ],
                    staticClass: "ml-2"
                  }),
                  _c(
                    "span",
                    { staticClass: "sidebar-office-mode-switch" },
                    [
                      _c("CSwitch", {
                        attrs: {
                          checked: !_vm.isDarkMode,
                          shape: "pill",
                          color: "info"
                        },
                        on: {
                          "update:checked": function() {
                            return _vm.$store.dispatch(
                              "UiStoreModule/toggleDarkMode"
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        2
      ),
      _c("CSidebarMinimizer", {
        staticClass: "c-d-md-down-none",
        nativeOn: {
          click: function($event) {
            return _vm.$store.commit("UiStoreModule/toggleSidebarMinimize")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }