var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.field
    ? _c(
        "CInputCheckbox",
        _vm._b(
          {
            attrs: {
              label: _vm.shownLabel,
              checked: _vm.checked,
              isValid: _vm.checkIfValid,
              id: _vm.safeId(),
              "data-testid": _vm.shownTestId
            },
            on: {
              "update:checked": function(val) {
                return _vm.updatedChecked(val)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "invalid-feedback",
                  fn: function() {
                    return [
                      _c("ValidatedInputErrors", {
                        attrs: { label: _vm.shownLabel, field: _vm.field }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "prepend-content",
                  fn: function() {
                    return [_vm._t("prepend-content")]
                  },
                  proxy: true
                },
                {
                  key: "label",
                  fn: function() {
                    return [
                      _vm._t("label", [
                        _vm.shownLabel || _vm.shownDescription
                          ? _c(
                              "label",
                              { attrs: { for: _vm.safeId() } },
                              [
                                _vm.isRequired
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "c-tooltip",
                                            rawName: "v-c-tooltip",
                                            value: {
                                              appendToBody: true,
                                              content: _vm.$t(
                                                "components.forms.required"
                                              )
                                            },
                                            expression:
                                              "{ appendToBody: true, content: $t('components.forms.required') }"
                                          }
                                        ]
                                      },
                                      [_vm._v(" * ")]
                                    )
                                  : _vm._e(),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.shownLabel)
                                  }
                                }),
                                _vm.shownDescription
                                  ? _c("CIcon", {
                                      directives: [
                                        {
                                          name: "c-tooltip",
                                          rawName: "v-c-tooltip",
                                          value: {
                                            appendToBody: true,
                                            content: _vm.shownDescription
                                          },
                                          expression:
                                            "{ appendToBody: true, content: shownDescription }"
                                        }
                                      ],
                                      staticClass: "infoIcon",
                                      attrs: { name: "cis-info-circle" }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          },
          "CInputCheckbox",
          _vm.$attrs,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }