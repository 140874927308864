var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CDropdown",
    {
      staticClass: "language-switch",
      attrs: { nav: "", placement: "bottom-end", color: "ghost", caret: false },
      scopedSlots: _vm._u([
        {
          key: "toggler-content",
          fn: function() {
            return [
              _c("CIcon", {
                attrs: { name: _vm.iconName(_vm.$i18n.locale), size: "xl" }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    _vm._l(_vm.languageOptions, function(languageOption) {
      return _c(
        "CDropdownItem",
        {
          key: languageOption.value,
          attrs: { hreflang: languageOption.value },
          on: {
            click: function($event) {
              return _vm.changeLocale(languageOption.value)
            }
          }
        },
        [_c("CIcon", { attrs: { name: languageOption.icon, size: "xl" } })],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }