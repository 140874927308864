var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardHeader", [
        _vm._v(
          _vm._s(
            _vm.$t("pages.owner.calendar.OwnerCalendar.CalendarSyncHeader")
          ) +
            " " +
            _vm._s(_vm.playroom.name)
        )
      ]),
      _c(
        "CCardBody",
        [
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "4" } },
                [
                  _c(
                    "CCard",
                    [
                      _c(
                        "CCardHeader",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "pages.owner.calendar.OwnerCalendar.CalendarImport"
                                )
                              ) +
                              " "
                          ),
                          _c("CIcon", {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: {
                                  appendToBody: true,
                                  content: _vm.$t(
                                    "pages.owner.calendar.OwnerCalendar.ImportHelp"
                                  )
                                },
                                expression:
                                  "{ appendToBody: true, content: $t('pages.owner.calendar.OwnerCalendar.ImportHelp') }"
                              }
                            ],
                            attrs: { name: "cis-info-circle" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCardBody",
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey: "playroom.icsCalendarUrl",
                              "data-cy": "icsCalendarUrl",
                              value: _vm.$v.playroom.icsCalendarUrl.$model,
                              field: _vm.$v.playroom.icsCalendarUrl
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.icsCalendarUrl,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _vm.playroom.icsCalendarLastUpdateAt
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "pages.owner.calendar.OwnerCalendar.LastImport",
                                        {
                                          date: _vm.formatIsoDateTimeTz(
                                            _vm.playroom.icsCalendarLastUpdateAt
                                          )
                                        }
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("CCardFooter", [
                        _c(
                          "div",
                          [
                            _c("CButton", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "pages.owner.calendar.OwnerCalendar.SaveAndImportButton",
                                  expression:
                                    "'pages.owner.calendar.OwnerCalendar.SaveAndImportButton'"
                                }
                              ],
                              attrs: { color: "secondary" },
                              on: { click: _vm.saveAndRefresh }
                            }),
                            _c("CButton", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "pages.owner.calendar.OwnerCalendar.DeleteButton",
                                  expression:
                                    "'pages.owner.calendar.OwnerCalendar.DeleteButton'"
                                }
                              ],
                              attrs: { color: "danger" },
                              on: { click: _vm.deleteIcsEntries }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "4" } },
                [
                  _c(
                    "CCard",
                    [
                      _c(
                        "CCardHeader",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "pages.owner.calendar.OwnerCalendar.PrivateCalendarExport"
                                )
                              ) +
                              " "
                          ),
                          _c("CIcon", {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: {
                                  appendToBody: true,
                                  content: _vm.$t(
                                    "pages.owner.calendar.OwnerCalendar.ExportHelp"
                                  )
                                },
                                expression:
                                  "{ appendToBody: true, content: $t('pages.owner.calendar.OwnerCalendar.ExportHelp') }"
                              }
                            ],
                            attrs: { name: "cis-info-circle" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCardBody",
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey:
                                "playroom.icsPrivateExportCalendarUrl",
                              value: _vm.$v.privateIcsStreamUrl.$model,
                              disabled: "disabled",
                              field: _vm.$v.privateIcsStreamUrl
                            }
                          }),
                          _c(
                            "div",
                            [
                              _c("ValidatedCheckbox", {
                                attrs: {
                                  field:
                                    _vm.$v
                                      .icsPrivateStreamUrlWithImportedEvents,
                                  checked:
                                    _vm.$v.icsPrivateStreamUrlWithImportedEvents
                                      .$model,
                                  translationKey:
                                    "playroom.icsStreamUrlWithImportedEvents"
                                },
                                on: {
                                  "update:checked": function($event) {
                                    return _vm.$set(
                                      _vm.$v
                                        .icsPrivateStreamUrlWithImportedEvents,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("CCardFooter", [
                        _c("div", [
                          _c("button", {
                            directives: [
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:copy",
                                value: _vm.privateIcsStreamUrl,
                                expression: "privateIcsStreamUrl",
                                arg: "copy"
                              },
                              {
                                name: "t",
                                rawName: "v-t",
                                value:
                                  "pages.owner.calendar.OwnerCalendar.CopyExportUrlButton",
                                expression:
                                  "'pages.owner.calendar.OwnerCalendar.CopyExportUrlButton'"
                              }
                            ],
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: { click: _vm.onCopyIcsPrivateUrl }
                          }),
                          _c("a", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value:
                                  "pages.owner.calendar.OwnerCalendar.ExportButton",
                                expression:
                                  "'pages.owner.calendar.OwnerCalendar.ExportButton'"
                              }
                            ],
                            staticClass: "btn btn-info",
                            attrs: {
                              href: _vm.privateIcsStreamUrl,
                              target: "_blank"
                            }
                          })
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "4" } },
                [
                  _c(
                    "CCard",
                    [
                      _c(
                        "CCardHeader",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "pages.owner.calendar.OwnerCalendar.PublicCalendarExport"
                                )
                              ) +
                              " "
                          ),
                          _c("CIcon", {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: {
                                  appendToBody: true,
                                  content: _vm.$t(
                                    "pages.owner.calendar.OwnerCalendar.ExportHelp"
                                  )
                                },
                                expression:
                                  "{ appendToBody: true, content: $t('pages.owner.calendar.OwnerCalendar.ExportHelp') }"
                              }
                            ],
                            attrs: { name: "cis-info-circle" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCardBody",
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey:
                                "playroom.icsPublicExportCalendarUrl",
                              value: _vm.$v.publicIcsStreamUrl.$model,
                              disabled: "disabled",
                              field: _vm.$v.publicIcsStreamUrl
                            }
                          }),
                          _c(
                            "div",
                            [
                              _c("ValidatedCheckbox", {
                                attrs: {
                                  field:
                                    _vm.$v.icsPublicStreamUrlWithImportedEvents,
                                  checked:
                                    _vm.$v.icsPublicStreamUrlWithImportedEvents
                                      .$model,
                                  translationKey:
                                    "playroom.icsStreamUrlWithImportedEvents"
                                },
                                on: {
                                  "update:checked": function($event) {
                                    return _vm.$set(
                                      _vm.$v
                                        .icsPublicStreamUrlWithImportedEvents,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("CCardFooter", [
                        _c("div", [
                          _c("button", {
                            directives: [
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:copy",
                                value: _vm.publicIcsStreamUrl,
                                expression: "publicIcsStreamUrl",
                                arg: "copy"
                              },
                              {
                                name: "t",
                                rawName: "v-t",
                                value:
                                  "pages.owner.calendar.OwnerCalendar.CopyExportUrlButton",
                                expression:
                                  "'pages.owner.calendar.OwnerCalendar.CopyExportUrlButton'"
                              }
                            ],
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: { click: _vm.onCopyIcsPublicUrl }
                          }),
                          _c("a", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value:
                                  "pages.owner.calendar.OwnerCalendar.ExportButton",
                                expression:
                                  "'pages.owner.calendar.OwnerCalendar.ExportButton'"
                              }
                            ],
                            staticClass: "btn btn-info",
                            attrs: {
                              href: _vm.publicIcsStreamUrl,
                              target: "_blank"
                            }
                          })
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }