var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.alerts.length > 0
    ? _c(
        "div",
        _vm._l(_vm.alerts, function(alert, index) {
          return _c(
            "CAlert",
            {
              key: "alert-" + index,
              attrs: { color: alert.color, closeButton: true }
            },
            [_vm._v(_vm._s(alert.message))]
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }