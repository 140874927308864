var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidatedCheckbox", {
        attrs: {
          field: _vm.$v.onlyFavorites,
          checked: _vm.$v.onlyFavorites.$model,
          translationKey: "playroomSearch.onlyFavorites"
        },
        on: {
          "update:checked": function($event) {
            return _vm.$set(_vm.$v.onlyFavorites, "$model", $event)
          }
        }
      }),
      _c("hr")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }