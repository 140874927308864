var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "support-modal" },
    [
      _c(
        "CModal",
        {
          staticClass: "supportmodal",
          attrs: {
            title: _vm.$t("components.TheFooter.contactSupport.message"),
            color: "warning",
            show: _vm.show,
            "data-cy": "cancel-modal",
            closeOnBackdrop: false
          },
          on: {
            "update:show": function($event) {
              return _vm.$emit("onClose")
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c("CButton", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value:
                          "components.TheFooter.contactSupport.label.cancel",
                        expression:
                          "'components.TheFooter.contactSupport.label.cancel'"
                      }
                    ],
                    attrs: { color: "light" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("onClose")
                      }
                    }
                  }),
                  _c("CButton", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value:
                          "components.TheFooter.contactSupport.label.confirm",
                        expression:
                          "'components.TheFooter.contactSupport.label.confirm'"
                      }
                    ],
                    attrs: { color: "primary", "data-cy": "submit" },
                    on: {
                      click: function($event) {
                        return _vm.sendEmail()
                      }
                    }
                  }),
                  _c("hr"),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "components.TheFooter.contactSupport.furtherInfo"
                        )
                      )
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "CForm",
            [
              _c("ValidatedSelect", {
                attrs: {
                  field: _vm.$v.contactSupport.supportType,
                  value: _vm.$v.contactSupport.supportType.$model,
                  options: _vm.options,
                  label: _vm.$t(
                    "components.TheFooter.contactSupport.label.supportType"
                  ),
                  translationKey: "contactSupport.supportType"
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(
                      _vm.$v.contactSupport.supportType,
                      "$model",
                      $event
                    )
                  }
                }
              }),
              !_vm.loggedin
                ? _c("ValidatedInput", {
                    attrs: {
                      value: _vm.$v.contactSupport.visitorName.$model,
                      field: _vm.$v.contactSupport.visitorName,
                      translationKey: "contactSupport.visitorName",
                      label: _vm.$t(
                        "components.TheFooter.contactSupport.label.visitorName"
                      )
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.contactSupport.visitorName,
                          "$model",
                          $event
                        )
                      }
                    }
                  })
                : _vm._e(),
              !_vm.loggedin
                ? _c("ValidatedInput", {
                    attrs: {
                      value: _vm.$v.contactSupport.visitorEmail.$model,
                      field: _vm.$v.contactSupport.visitorEmail,
                      translationKey: "contactSupport.visitorEmail",
                      label: _vm.$t(
                        "components.TheFooter.contactSupport.label.visitorEmail"
                      )
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.contactSupport.visitorEmail,
                          "$model",
                          $event
                        )
                      }
                    }
                  })
                : _vm._e(),
              _c("ValidatedInput", {
                attrs: {
                  value: _vm.$v.contactSupport.subject.$model,
                  field: _vm.$v.contactSupport.subject,
                  translationKey: "contactSupport.subject",
                  label: _vm.$t(
                    "components.TheFooter.contactSupport.label.subject"
                  )
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(
                      _vm.$v.contactSupport.subject,
                      "$model",
                      $event
                    )
                  }
                }
              }),
              _c("ValidatedTextArea", {
                attrs: {
                  value: _vm.$v.contactSupport.content.$model,
                  field: _vm.$v.contactSupport.content,
                  translationKey: "contactSupport.content",
                  label: _vm.$t(
                    "components.TheFooter.contactSupport.label.content"
                  )
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(
                      _vm.$v.contactSupport.content,
                      "$model",
                      $event
                    )
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("Notification", {
        attrs: {
          icon: "cilCheck",
          text: _vm.$t(
            "components.TheFooter.contactSupport.notificationMsg.success"
          ),
          show: _vm.showSuccessNotification
        },
        on: {
          onClose: function($event) {
            _vm.showSuccessNotification = false
          }
        }
      }),
      _c("Notification", {
        attrs: {
          icon: "cilWarning",
          text: _vm.$t(
            "components.TheFooter.contactSupport.notificationMsg.failure"
          ),
          show: _vm.showErrorNotification
        },
        on: {
          onClose: function($event) {
            _vm.showErrorNotification = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }