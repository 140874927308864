var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      {
        staticClass: "c-app",
        class: {
          "c-dark-theme": _vm.isDarkMode,
          "c-white-theme": !_vm.isDarkMode
        }
      },
      [
        _c("ToasterComponent"),
        _c("TheSidebar"),
        _c(
          "CWrapper",
          [
            _c("TheHeader"),
            _c(
              "div",
              { staticClass: "c-body" },
              [
                _c("DemoHeader"),
                _c(
                  "main",
                  { staticClass: "c-main" },
                  [
                    _c(
                      "CContainer",
                      { attrs: { fluid: "" } },
                      [_c("Alerts"), _c("NextActivities"), _c("router-view")],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("TheFooter")
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }