var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nextActivities.length > 0
    ? _c(
        "div",
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", [_vm._v("Aktuelles")]),
              _c(
                "CCardBody",
                [
                  _vm._l(_vm.nextCalendarActivities, function(nextActivity) {
                    return _c("div", { key: nextActivity.id }, [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.calendarActivityText(nextActivity)
                          )
                        }
                      })
                    ])
                  }),
                  _vm.nextCalendarActivities.length > 0 ? _c("hr") : _vm._e(),
                  _vm._l(_vm.nextActableActivities, function(nextActivity) {
                    return _c("div", { key: nextActivity.id }, [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.actableActivityText(nextActivity)
                          )
                        }
                      })
                    ])
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }