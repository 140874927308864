var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      false
        ? _c("div", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "pages.playroomSearch.popularFilter",
                expression: "'pages.playroomSearch.popularFilter'"
              }
            ],
            staticClass: "title"
          })
        : _vm._e(),
      false
        ? _c("ValidatedCheckbox", {
            attrs: {
              field: _vm.$v.rating,
              checked: _vm.$v.rating.$model,
              translationKey: "playroomSearch.popularFilteRating"
            },
            on: {
              "update:checked": function($event) {
                return _vm.$set(_vm.$v.rating, "$model", $event)
              }
            }
          })
        : _vm._e(),
      false
        ? _c("ValidatedCheckbox", {
            attrs: {
              field: _vm.$v.selfCheckIn,
              checked: _vm.$v.selfCheckIn.$model,
              translationKey: "playroomSearch.popularFilterSelfCheck"
            },
            on: {
              "update:checked": function($event) {
                return _vm.$set(_vm.$v.selfCheckIn, "$model", $event)
              }
            }
          })
        : _vm._e(),
      false
        ? _c("ValidatedCheckbox", {
            attrs: {
              field: _vm.$v.sauna,
              checked: _vm.$v.sauna.$model,
              translationKey: "playroomSearch.popularFilterSauna"
            },
            on: {
              "update:checked": function($event) {
                return _vm.$set(_vm.$v.sauna, "$model", $event)
              }
            }
          })
        : _vm._e(),
      _vm.isLocationEnter
        ? _c("CSelect", {
            attrs: {
              options: _vm.radiusOptions,
              label: _vm.$t(
                "forms.playroomSearch.popularFilterSearchRadius.label"
              ),
              "data-testid": "playroomSearch.popularFilterSearchRadius",
              value: _vm.maxDistance
            },
            on: {
              "update:value": function($event) {
                _vm.maxDistance = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }