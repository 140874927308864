var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-cal", {
        staticClass: "vuecal--blue-theme",
        staticStyle: { height: "600px" },
        attrs: {
          events: _vm.events,
          "active-view": _vm.activeView,
          locale: "de",
          "events-on-month-view": "short",
          "disable-views": ["years", "year"],
          showAllDayEvents: true,
          "selected-date": _vm.selectedDate
        },
        on: {
          "view-change": function($event) {
            return _vm.$emit("view-change", $event.startDate)
          }
        }
      }),
      _c("br"),
      _c("strong", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "pages.owner.calendar.OwnerCalendar.legend",
            expression: "'pages.owner.calendar.OwnerCalendar.legend'"
          }
        ]
      }),
      _c(
        "CContainer",
        [
          _c(
            "CRow",
            { staticClass: "legend" },
            [
              _c("CCol", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "enums.occupationType.BookingUnconfirmed",
                    expression: "'enums.occupationType.BookingUnconfirmed'"
                  }
                ],
                staticClass: "vuecal__event BookingUnconfirmed",
                attrs: { lg: "2", "offset-lg": "1" }
              }),
              _c("CCol", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "enums.occupationType.Booking",
                    expression: "'enums.occupationType.Booking'"
                  }
                ],
                staticClass: "vuecal__event Booking",
                attrs: { lg: "2", "offset-lg": "1" }
              }),
              _c("CCol", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "enums.occupationType.CleaningTime",
                    expression: "'enums.occupationType.CleaningTime'"
                  }
                ],
                staticClass: "vuecal__event CleaningTime",
                attrs: { lg: "2", "offset-lg": "1" }
              }),
              _c("CCol", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "enums.occupationType.TemporalUnavailabilities",
                    expression:
                      "'enums.occupationType.TemporalUnavailabilities'"
                  }
                ],
                staticClass: "vuecal__event TemporalUnavailabilities",
                attrs: { lg: "2", "offset-lg": "1" }
              }),
              _c("CCol", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "enums.occupationType.CalendarImport",
                    expression: "'enums.occupationType.CalendarImport'"
                  }
                ],
                staticClass: "vuecal__event CalendarImport",
                attrs: { lg: "2", "offset-lg": "1" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }