var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.field
    ? _c(
        "CTextarea",
        _vm._g(
          _vm._b(
            {
              staticClass: "textarea",
              attrs: {
                value: _vm.value,
                isValid: _vm.checkIfValid,
                label: _vm.shownLabel,
                id: _vm.safeId(),
                maxlength: _vm.maxLength,
                description: _vm.lengthDescription,
                placeholder: _vm.placeholder
              },
              on: {
                focus: function($event) {
                  _vm.$nextTick(function() {
                    return _vm.$emit("focusChange", _vm.name)
                  })
                },
                focusout: function($event) {
                  return _vm.$emit("focusChange", "")
                },
                change: function($event) {
                  return _vm.onChange($event)
                },
                keyup: function($event) {
                  return _vm.keyUpEvent($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "invalid-feedback",
                    fn: function() {
                      return [
                        _c("ValidatedInputErrors", {
                          attrs: {
                            label: _vm.shownLabel || _vm.placeholder,
                            field: _vm.field
                          }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _vm._t("label", [
                          _vm.shownLabel || _vm.shownDescription
                            ? _c(
                                "label",
                                { attrs: { for: _vm.safeId() } },
                                [
                                  _vm._v(" " + _vm._s(_vm.shownLabel) + " "),
                                  _vm.isRequired
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "c-tooltip",
                                              rawName: "v-c-tooltip",
                                              value: {
                                                appendToBody: true,
                                                content: _vm.$t(
                                                  "components.forms.required"
                                                )
                                              },
                                              expression:
                                                "{ appendToBody: true, content: $t('components.forms.required') }"
                                            }
                                          ]
                                        },
                                        [_vm._v(" * ")]
                                      )
                                    : _vm._e(),
                                  _vm.shownDescription
                                    ? _c("CIcon", {
                                        directives: [
                                          {
                                            name: "c-tooltip",
                                            rawName: "v-c-tooltip",
                                            value: {
                                              appendToBody: true,
                                              content: _vm.shownDescription
                                            },
                                            expression:
                                              "{ appendToBody: true, content: shownDescription }"
                                          }
                                        ],
                                        attrs: { name: "cis-info-circle" }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            "CTextarea",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }