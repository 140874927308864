var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "pages.playroomSearch.ratingTitle",
            expression: "'pages.playroomSearch.ratingTitle'"
          }
        ],
        staticClass: "title"
      }),
      _c("CSelect", {
        attrs: {
          "data-testid": "playroomSearch.rating",
          options: _vm.ratingValueOptions,
          value: _vm.rating,
          label: _vm.$t("forms.playroomSearch.rating.label")
        },
        on: {
          "update:value": function($event) {
            _vm.rating = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }