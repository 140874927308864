var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CFooter",
    [
      _c(
        "cookie-law",
        {
          attrs: {
            theme: "base",
            buttonText: "Akzeptieren",
            "data-testid": "cookie-wrapper"
          }
        },
        [
          _c("div", {
            attrs: { slot: "message", "data-testid": "cookie-banner" },
            domProps: {
              innerHTML: _vm._s(_vm.$t("components.TheFooter.cookieMessage"))
            },
            slot: "message"
          })
        ]
      ),
      !_vm.isProduction
        ? _c("div", { staticClass: "version-info" }, [
            _vm._v(
              " Version: FE" +
                _vm._s(_vm.version) +
                " / BE" +
                _vm._s(_vm.backendVersion) +
                " / " +
                _vm._s(_vm.enviroment_infos) +
                " / Locale: " +
                _vm._s(_vm.$i18n.locale) +
                " / WebSocket: " +
                _vm._s(_vm.webSocketStatus) +
                " "
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "link-list" },
        [
          _c("router-link", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "components.TheFooter.imprint",
                expression: "'components.TheFooter.imprint'"
              }
            ],
            attrs: { to: { name: "PublicImprint" } }
          }),
          _vm._v(" | "),
          _c("router-link", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "components.TheFooter.contactSupport.message",
                expression: "'components.TheFooter.contactSupport.message'"
              }
            ],
            attrs: { to: { name: "" } },
            nativeOn: {
              click: function($event) {
                _vm.showSupportModal = true
              }
            }
          }),
          _vm._v(" | "),
          _c("a", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "components.TheFooter.privacy",
                expression: "'components.TheFooter.privacy'"
              }
            ],
            attrs: {
              target: "_blank",
              href: "/documents/de/Datenschutzerkl%C3%A4rung.pdf"
            }
          }),
          _vm._v(" | "),
          _c("a", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "components.TheFooter.businessTerms",
                expression: "'components.TheFooter.businessTerms'"
              }
            ],
            attrs: {
              target: "_blank",
              href: "/documents/de/" + _vm.businessTermsFileName
            }
          })
        ],
        1
      ),
      _c("SupportModal", {
        attrs: { show: _vm.showSupportModal },
        on: {
          onClose: function($event) {
            _vm.showSupportModal = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }