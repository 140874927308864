var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "pages.playroomSearch.houseRulesTitle",
            expression: "'pages.playroomSearch.houseRulesTitle'"
          }
        ],
        staticClass: "title"
      }),
      _c("ValidatedCheckbox", {
        attrs: {
          field: _vm.$v.smokingAllowed,
          checked: _vm.$v.smokingAllowed.$model,
          translationKey: "playroomSearch.smokingAllowed"
        },
        on: {
          "update:checked": function($event) {
            return _vm.$set(_vm.$v.smokingAllowed, "$model", $event)
          }
        }
      }),
      _c("ValidatedCheckbox", {
        attrs: {
          field: _vm.$v.partyEventAllowed,
          checked: _vm.$v.partyEventAllowed.$model,
          translationKey: "playroomSearch.partyEventAllowed"
        },
        on: {
          "update:checked": function($event) {
            return _vm.$set(_vm.$v.partyEventAllowed, "$model", $event)
          }
        }
      }),
      _c("ValidatedCheckbox", {
        attrs: {
          field: _vm.$v.commercialUsageAllowed,
          checked: _vm.$v.commercialUsageAllowed.$model,
          translationKey: "playroomSearch.commercialUsageAllowed"
        },
        on: {
          "update:checked": function($event) {
            return _vm.$set(_vm.$v.commercialUsageAllowed, "$model", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }