var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidatedInput", {
    attrs: {
      translationKey: "playroomSearch.countOfGuest",
      type: "number",
      min: "2",
      max: "30",
      field: _vm.$v.guestCount,
      value: _vm.$v.guestCount.$model
    },
    on: {
      "update:value": function($event) {
        return _vm.$set(_vm.$v.guestCount, "$model", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }