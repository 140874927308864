var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        [
          _c("div", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "pages.playroomSearch.featureTitle",
                expression: "'pages.playroomSearch.featureTitle'"
              }
            ],
            staticClass: "title"
          }),
          _vm._l(_vm.$v.featureFilterModel.$each.$iter, function(feature) {
            return _c(
              "div",
              {
                key: feature.$model.id,
                attrs: {
                  "data-featureTranslationKey": feature.$model.translationKey
                }
              },
              [
                _c("ValidatedCheckbox", {
                  attrs: {
                    field: feature.hasFilter,
                    checked: feature.hasFilter.$model,
                    label: _vm.$t("features." + feature.$model.translationKey),
                    testId: "playroomSearch." + feature.$model.translationKey
                  },
                  on: {
                    "update:checked": function($event) {
                      return _vm.$set(feature.hasFilter, "$model", $event)
                    }
                  }
                })
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }