var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CSelect", {
    attrs: {
      options: _vm.bookingTypeOptions,
      label: _vm.$t("forms.playroomSearch.bookingType.label"),
      "data-testid": "playroomSearch.bookingType",
      value: _vm.bookingType
    },
    on: {
      "update:value": function($event) {
        _vm.bookingType = $event
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }