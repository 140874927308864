var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "delete-button-container" },
    [
      _vm.type === "icon"
        ? _c("CButtonClose", {
            staticClass: "top-delete-btn",
            on: { click: _vm.openModal }
          })
        : _vm._e(),
      _vm.type === "text"
        ? _c(
            "CButton",
            {
              staticClass: "delete-button",
              attrs: { color: "danger", disabled: _vm.disabled },
              on: { click: _vm.openModal }
            },
            [
              _c("span", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "forms.common.delete",
                    expression: "'forms.common.delete'"
                  }
                ]
              })
            ]
          )
        : _vm._e(),
      _c("DeleteConfirmationModal", {
        attrs: { show: _vm.showModal, message: _vm.message },
        on: { delete: _vm.internalOnDelete, close: _vm.closeModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }