var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gmap-autocomplete", {
    attrs: { options: { fields: ["geometry", "address_components"] } },
    on: { place_changed: _vm.setAutocompletePlace },
    scopedSlots: _vm._u([
      {
        key: "input",
        fn: function(slotProps) {
          return [
            _c("div", { staticClass: "form-group", attrs: { role: "group" } }, [
              _c("div", { staticClass: "input-group" }, [
                _c("div", { staticClass: "input-group-prepend" }, [
                  _c(
                    "span",
                    { staticClass: "input-group-text" },
                    [_c("CIcon", { attrs: { name: "cil-location-pin" } })],
                    1
                  )
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.locSearch,
                      expression: "locSearch"
                    }
                  ],
                  ref: "input",
                  staticClass: "form-control",
                  attrs: {
                    outlined: "",
                    "data-cy": "playroomSearch.location",
                    placeholder: _vm.$t("pages.playroomSearch.location")
                  },
                  domProps: { value: _vm.locSearch },
                  on: {
                    listeners: slotProps.listeners,
                    attrs: slotProps.attrs,
                    blur: _vm.inputChange,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.locSearch = $event.target.value
                    }
                  }
                })
              ])
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }